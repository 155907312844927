<template>
  <div class="feature-card" v-if="card">
    <div class="feature-card-content" :style="`--color:${card.color ? card.color : '#bd0909'}`">
      <div class="feature-card-left">
        <div class="feature-card-icon" :style="`--iconSize:${card.iconSize || '250%'}`">
          <figure class="figure"><iconic :name="card.icon" /></figure>
        </div>
        <div class="feature-card-info">
          <div class="feature-card-info-content">
            <div class="feature-card-info-label">{{ card.label }}</div>
            <div class="feature-card-info-desc">{{ card.desc }}</div>
          </div>
        </div>
      </div>
      <div class="feature-card-right">
        <span class="rightIcon"><iconic name="mf_check" /></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["card"],
};
</script>

<style lang="scss">
$iconWidth: 72px;
.feature-card {
  position: relative;
  margin: $mpadding 0;
  &-content {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: #fff;
    @include shadow;
    border-radius: $mpadding/2;
  }
  &-left {
    flex: 1;
    display: flex;
  }
  &-icon {
    width: $iconWidth;
    min-width: $iconWidth;
    height: auto;
    background-image: var(--sec-gradient);
    @include Flex(inherit, center, center);
    border-radius: $mpadding/2;
    font-size: var(--iconSize);
    .iconic {
      @include Flex(inherit, center, center);
      color: #fff;
    }
  }
  &-info {
    padding: $mpadding $mpadding;
    display: flex;
    align-items: center;
    &-label {
      font-weight: bold;
      font-size: 130%;
    }
    &-desc {
      opacity: 0.5;
      margin: $mpadding/4 0 0 0;
      font-weight: bold;
      font-size: 90%;
    }
    .progress-bar {
      margin-top: $mpadding/2;
    }
  }
  &-right {
    text-align: right;
    .rightIcon {
      font-size: 200%;
      font-weight: bold;
      margin: $mpadding/2 $mpadding $mpadding/2 0;
      color: $primary_color;
    }
  }
}
</style>
